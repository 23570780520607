<script lang="ts">
	import { BROWSER } from 'esm-env'
	import { onMount } from 'svelte'
	import { sendGTM } from '$lib/analytics'
	import Header from '$lib/components/header-3.0-a/Header.svelte'
	import DynamicPageWrapper from '$lib/modules/cms/DynamicPageWrapper/DynamicPageWrapper.svelte'
	import { t } from '$lib/translations'
	import { getCookie, setCookie } from '$lib/utils'
	import type { PageData } from './$types'
	import { page } from '$app/stores'

	export let data: PageData

	if (BROWSER && (data.altHeader === 'true' || data.altHeader === 'false')) {
		const cachedAltHeader = getCookie('ALT_HEADER')

		if (cachedAltHeader == undefined) setCookie('ALT_HEADER', data.altHeader?.toString(), 0)
	}

	onMount(() => {
		sendGTM(
			'set_ab_test',
			{},
			{
				ab_test: data.altHeader === 'true' ? 'With_Contact_Fields' : 'Control'
			}
		)
	})
</script>

<Header
	title={data.headerData?.title || t('web.homepage.woltadvisorHeader')}
	showHVAC={data.headerData?.showHVAC}
	showPV={data.headerData?.showPV}
	uspBoxes={data.headerData?.uspBoxes}
	rating={data.headerData?.rating}
	showContactFields={data.altHeader === 'true'}
	splitTestActive={data.abTestActive}
/>
<DynamicPageWrapper components={data.components} type="productPage" />
